export const cores = {
    verde: '#34BC6C',
    branco: 'rgba(255,255,255,0.8)',
    pretoFundo: '#202020',
    pretoFundo2: '#2D2E32',
    html: '#D84924',
    css: '#1A6DB1',
    js: '#EAD41C',
    bootstrap: '#7A10ED',
    php: '#7175AA',
    reactjs: '#5AC8E6',
    mysql_azul: '#4F7C99',
    mysql_laranja: '#D76F2A',
    git: '#E44C30',
    jquery: '#1064A5',
    sass: '#C26191',
    linkedin: '#0A66C2',
    gmail: '#BA201D',
    wpp: '#25C862',
    github: '#0D1117',
    tailwind: '#38BDF8',
    npm: '#C13534',
    firebase: '#FFCB2E'
}

