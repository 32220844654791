import { createGlobalStyle } from "styled-components";
import { cores } from "./cores";
import styled, { keyframes } from 'styled-components';

const aumentarDiminuir = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

export const AnimatedDiv = styled.div`
  animation: ${aumentarDiminuir} 3s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1{
      color: ${cores.branco};
      margin-bottom: 2rem;
  }
`;

export const GlobalStyle = createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
main {
    width: 100%;
    height: 100vh;
    background-color: ${cores.pretoFundo};
    display: flex;
    justify-content: center;
    align-items: center;
}
`
