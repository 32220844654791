import { AnimatedDiv, GlobalStyle } from "./GlobalStyle";
import { FaTools } from "react-icons/fa";
import { cores } from "./cores";

function App() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <main>
        <AnimatedDiv>
          <h1>Em manutenção...</h1>
          <FaTools size={80} color={cores.branco} />
        </AnimatedDiv>
      </main>
    </>
  );
}

export default App;
